import React from "react";
import MainMenu from "./mainMenu";

const MenuModal = ({props, state}) => {
  return (
    <div>
      <div className="modal modal--menu">
        <div className="modal__box">
          <MainMenu state={state}/>
        </div>
      </div>
    </div>
  )
}

export default MenuModal;
