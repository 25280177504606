import React, { useState } from "react";
import {StaticQuery, graphql, Link} from "gatsby";
import Modal from "react-modal";
// import {createLocalLink} from "../../utils";

const renderMenuItem = item => {
  return (
    <li className="menu__item" key={item.wordpress_id} role="none">
      <Link className="menu__link"
            activeClassName="active"
            role="menuitem"
            to={item.url === '/blog' ? `/blog` : `/${item.slug}`}
        >
        {item.title}
      </Link>
    </li>
  )
}

const MainMenu = ({props, state}) => {
  const [isModal, setModal] = useState(false);

  return (
    <StaticQuery
      query={graphql`
      query GET_MAIN_MENU {
        allWordpressMenusMenusItems {
          edges {
            node {
              name
              items {
                url
                slug
                title
                wordpress_id
              }
            }
          }
        }
      }
    `}
      render={data => (
        <div className="menu">
          <button className="burger" type="button" onClick={() => {
            setModal(true);
          }}>
              <span className="burger__line">
              </span>
          </button>
          <nav className="menu__nav" aria-label="Main menu">
            <ul className="menu__list">{/*role="menu"*/}
              {/*{console.log(data.allWordpressMenusMenusItems.edges[0].node)}*/}
              {data.allWordpressMenusMenusItems.edges[0].node.items.map(item => renderMenuItem(item))}
            </ul>
          </nav>

          <Modal
            closeTimeoutMS={200}
            className="custom-modal"
            ariaHideApp={false}
            isOpen={isModal}
            contentLabel="Example Modal"
          >
            <div className="custom-modal__button">
              <button
                className="custom-modal__button-close"
                onClick={() => {
                  setModal(false);
                }}>
                <span></span>
                <span></span>
                Close Modal
              </button>
            </div>

            <ul className="custom-modal__menu">{/*role="menu"*/}
              {data.allWordpressMenusMenusItems.edges[0].node.items.map(item => renderMenuItem(item))}
            </ul>
          </Modal>
        </div>
      )}
    />
  )
}

export default MainMenu
