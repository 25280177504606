import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import MainMenu from "./partials/mainMenu";

const Header = ({ siteTitle, state }) => (
  <header className='header'>
    <div className="container">
      <div className="header__box">
        <h1 className="header__title">
            <Link className="header__logo" to="/">
              <svg width="30px" height="30px"
                   viewBox="0 0 556 556"
                   xmlns="http://www.w3.org/2000/svg"
                   fillRule="evenodd"
                   clipRule="evenodd"
                   strokeLinejoin="round"
                   strokeMiterlimit="2"><path fill="none" d="M0 0h555.364v555.363H0z"/><path fill="#006464" d="M37.818 102V.33h239.88c49.08 0 91.7 8.18 127.85 24.54 36.16 16.37 64 39.59 83.53 69.67 19.53 30.09 29.29 65.71 29.29 106.88s-9.9 76.661-29.69 106.481c-19.79 29.82-47.89 52.65-84.31 68.48l124.29 178.13h-137.75l-106.88-154.38h-117.96v154.38H37.818v-254.96h349.17a42.82 42.82 0 0030.26-12.53 42.82 42.82 0 0012.53-30.26V144.79a42.797 42.797 0 00-42.79-42.79h-77.22l-22.67-37.51-120.26-.04-22.68 37.55H37.818zm0 0v197.551h-.02l.01-197.55h.01z" fillRule="nonzero"/><circle fill="#006464" cx="1487.45" cy="1627.94" r="53.209" transform="translate(-1758.974 -1974.299) scale(1.33592)"/></svg>
              REINISFOTO.NET
            </Link>
        </h1>
        <MainMenu state={state}/>
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
